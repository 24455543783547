export interface IState {
	card: ICard,
	options: IOptions,
	translation: {}
}

export interface IOptions {
	layout: string,
	imagePosition: string,
	showImage: string
}

export interface ICard {
	imageUrl: string,
	imageHTML: string,
	date: string,
	articleType: string,
	heading: string,
	preamble: string,
	url: string,
	backgroundColor: string
}

const deafultState: IState = {
	card: {imageUrl: '', imageHTML: '', date: '', articleType: '', heading: '', preamble: '', url: '', backgroundColor: ''},
	options: {
		layout: 'vertical',
		imagePosition: 'left',
		showImage: 'true'
	},
	translation: {}
}

export default deafultState;